/* Prevent horizontal overflow globally */
body, html {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

.about {
  display: flex;
  justify-content: space-between; /* Align text and image to left and right */
  align-items: center;
  background-color: black;
  color: white;
  min-height: 100vh; /* Ensure the section takes full screen height */
  padding: 20px;
  padding-left: 5%;
  width: 100%; /* Prevent overflow of section */
  box-sizing: border-box; /* Make sure padding doesn't add extra width */
  overflow: hidden; /* Prevent overflow on the section */
}

.about-content {
  display: flex;
  justify-content: space-between; /* Space between text and image */
  align-items: center;
  width: 100%;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  overflow: hidden; /* Prevent child elements from overflowing */
}

.about-text {
  max-width: 50%;
  text-align: justify; /* Justify text */
  margin-bottom: 20px;
}

.about-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #1db954;
}

.location {
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
  color: #abffc9;
}

.about-description {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify; /* Justify description text */
}

.about-image {
  max-width: 30%; /* Reduced image width */
  order: 2; /* Ensuring the image is on the right */
  margin-left: 20px; /* Move image slightly to the right */
  transition: transform 0.3s ease-in-out; /* Smooth transition for images */
  flex-shrink: 0; /* Prevent image from shrinking */
  overflow: hidden; /* Prevent image overflow */
}

/* Hover effect for the image */
.joy-image {
  width: 90%;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Add transition for hover effect */
}

/* Apply hover effect to zoom the image and adjust opacity */
.joy-image:hover {
  transform: scale(1.1); /* Zoom effect */
  opacity: 0.9; /* Slightly fade the image */
}

/* Optional: If you want to add a subtle filter on hover (like grayscale) */
.joy-image:hover {
  filter: grayscale(20%) brightness(110%); /* Apply a slight grayscale effect and brightness */
}

.social-icons {
  display: flex;
  justify-content: left;
  gap: 20px;
  margin-top: 20px;
}

.social-icon {
  color: white;
  font-size: 30px;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #1db954; /* Default hover color for Spotify */
}

.social-icon:nth-child(1):hover {
  color: #1877f2; /* Facebook color */
}

.social-icon:nth-child(2):hover {
  color: #e4405f; /* Instagram color */
}

.social-icon:nth-child(3):hover {
  color: #1da1f2; /* Twitter color */
}

.social-icon:nth-child(4):hover {
  color: #ff0000; /* Youtube color */
}

.social-icon:nth-child(5):hover {
  color: #1db954; /* Spotify color */
}

/* Mobile and tablet responsiveness */
@media screen and (max-width: 768px) {
  .about-content {
    flex-direction: column;
    align-items: center; /* Center align items vertically */
    text-align: center; /* Center text on smaller screens */
  }

  .about-text {
    max-width: 100%;
    margin-bottom: 20px;
    text-align: left;
  }

  .about-image {
    max-width: 80%;
    margin-top: 20px;
    margin-left: 0;
    order: 1; /* Ensure image appears after text in mobile view */
  }

  .joy-image {
    width: 100%; /* Adjust image width on smaller screens */
    height: auto;
    border-radius: 10px;
  }

  .social-icons {
    justify-content: center;
    margin-top: 20px;
  }
}

@media screen and (max-width: 480px) {
  .about-title {
    font-size: 1.8rem;
  }

  .about-description {
    font-size: 1rem;
  }

  .social-icon {
    font-size: 24px;
  }
}
