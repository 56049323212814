/* Hero Section Styling */
.hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: black;
  overflow: hidden;
  padding: 0 20px;
  text-align: center;
}

.hero-content {
  color: white;
  z-index: 10;
}

.hero-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #1db954;
}

.hero-subtitle {
  font-size: 1.2rem;
  margin-top: 10px;
}

.cta-button {
  background-color: rgba(31, 31, 31, 0.2); /* Transparent button */
    color: white;
    padding: 8px 16px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 20px;
    cursor: pointer;
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    transition: all 0.3s ease-in-out;
    margin-right: 2%;
}

.cta-button:hover {
  background-color: rgba(233, 233, 233, 0.3);
    color: rgb(255, 255, 255);
}

.musical-notes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  /* Floating notes will go here */
}

/* Float animation for notes */
@keyframes float {
  0% {
    transform: translateY(0) scale(1);
    opacity: 0;
  }
  50% {
    opacity: 0.2;
    transform: translateY(-20px) scale(1.5);
  }
  100% {
    transform: translateY(-50px) scale(1);
    opacity: 0;
  }
}

/* Floating notes animation */
.note {
  position: absolute;
  color: white; /* Notes are white */
  opacity: 0;
  animation: float 3s infinite ease-in-out;
}

/* Responsive adjustments for mobile view */
@media screen and (max-width: 768px) {
  .hero-title {
    font-size: 2rem;
  }

  .cta-button {
    font-size: 1rem;
    padding: 8px 16px;
  }
}

@media screen and (max-width: 480px) {
  .hero-title {
    font-size: 1.6rem;
  }

  .cta-button {
    font-size: 0.9rem;
    padding: 6px 12px;
  }
}
