/* Music Section */
.music-section {
  padding: 40px;
  text-align: center;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center all content */
}

/* Title and Subtitle */
.music-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #1db954;
}

.music-subtitle {
  font-size: 1.2rem;
  margin-bottom: 40px;
}

/* Grid for Cards */
.music-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

/* Card Styles */
.music-card {
  width: 250px;
  height: 500px;
  background-color: rgba(24, 24, 24, 0.356);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  overflow: hidden;
  position: relative;
  transition: transform 0.4s ease, box-shadow 0.4s ease, background-color 0.4s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  padding-bottom: 10%;
}

/* Updated Hover Effect */
.music-card:hover {
  transform: scale(1.1) rotate(3deg); /* Slightly larger scale and rotation */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.7); /* Stronger shadow */
  background-color: rgba(24, 24, 24, 0.7); /* Slightly darker background */
}


/* Card Content Layout */
.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  text-align: center;
  padding-bottom: 20px; /* Add bottom padding for more spacing */
}

/* Album Art - Square */
.album-art {
  width: 100%; /* Full width of the card */
  height: 100%; /* Make it square */
  aspect-ratio: 1; /* Ensures it's square */
  object-fit: cover;
  border-radius: 10px; /* Optional rounded corners */
  margin-bottom: 15px; /* Added space below the album art */
}

/* Song Details Section */
.song-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1; /* Allow this section to grow */
  text-align: center;
}

.song-name {
  font-size: 1.3rem; /* Adjusted font size */
  font-weight: bold;
  margin-top: 10px;
  color: #fff;
}

.release-date {
  font-size: 1rem;
  margin: 5px 0;
  color: rgba(255, 255, 255, 0.7);
}

/* Play Button */
.play-button {
  background-color: #1db954;
  color: white;
  border: none;
  width: 50px;  /* Set width explicitly */
  height: 50px; /* Set height explicitly */
  padding: 0;   /* Remove padding */
  cursor: pointer;
  border-radius: 50%; /* Circle play button */
  font-size: 1.5rem; /* Adjusted font size */
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: auto; /* Center the play button */
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

.play-button:hover {
  background-color: #1ed760;
  transform: scale(1.1); /* Slightly enlarge play button */
}

/* Song Description */
.song-description {
  font-size: 1rem; /* Adjusted font size */
  line-height: 1.3;
  margin-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  color: rgba(255, 255, 255, 0.8);
  height: 70px; /* Sufficient height for longer descriptions */
  overflow: hidden; /* Hide overflowed text */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
}

/* Music Icons */
.music-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.music-icons a {
  color: white;
  font-size: 2rem;
  transition: color 0.3s ease;
}

.music-icons a:hover {
  color: #1db954;
}

/* Mobile Responsiveness */
@media screen and (max-width: 768px) {
  .music-cards {
      flex-direction: column;
      align-items: center;
  }

  .music-card {
      width: 90%;
      height: 550px; /* Keep card height consistent */
      margin-bottom: 20px;
  }

  .music-card.large {
      width: 95%;
  }
}
