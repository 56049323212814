/* Navbar container styling */
.navbar {
    position: fixed; /* Fix navbar to the top */
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 90px;
    background: rgba(26, 26, 26, 0.1); /* Frosted glass effect */
    backdrop-filter: blur(10px); /* Glassmorphism effect */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

    z-index: 1000; /* Ensure it's above other elements */
    transition: top 0.3s; /* Smooth transition when the navbar hides */
  }
  
  /* Navbar brand (Joy's Music) styling */
  .navbar-brand {
    font-family: 'Playfair Display', serif; /* Elegant, professional font */
    font-size: 1.8rem;
    font-weight: bold;
    color: white;
  }
  
  /* Connect button styling */
  .connect-btn {
    background-color: rgba(31, 31, 31, 0.2); /* Transparent button */
    color: white;
    padding: 8px 16px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 20px;
    cursor: pointer;
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    transition: all 0.3s ease-in-out;
    margin-right: 8.4%;
  }
  
  .connect-btn:hover {
    background-color: rgba(233, 233, 233, 0.3);
    color: rgb(255, 255, 255);
  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 768px) {
    .navbar {
      padding: 10px;
    }
  
    .navbar-brand {
      font-size: 1.5rem;
    }
  
    .connect-btn {
      font-size: 0.9rem;
      padding: 6px 12px;
      margin-right: 5%;
    }
  }
  