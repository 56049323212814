/* Skills & Certifications Section */
.skills-certifications-section {
    padding: 2% 4.7%;
    background-color: #000; /* Black background */
    color: #fff; /* White text */
    font-family: "Arial", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .intro-text {
    text-align: center;
    margin-bottom: 3%;
  }
  
  .intro-text h2 {
    font-size: 2.5rem;
    color: #1db954; /* Highlight color */
    margin-bottom: 10px;
  }
  
  .intro-text p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #ddd; /* Softer white */
  }
  
  .skills-container, .certifications-container {
    width: 100%;
    margin: 0% 0;
  }
  
  .skills-container h3, .certifications-container h3 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #1db954;
  }
  
  .skills-container ul {
    list-style-type: none;
    padding: 0;
  }
  
  .skills-container li {
    margin-bottom: 10px;
    font-size: 1.1rem;
    color: #ddd;
  }
  
  .certification-card {
    background: rgba(255, 255, 255, 0.1); /* Transparent white */
    border: 1px solid rgba(255, 255, 255, 0.3); /* Border for glassmorphism */
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Slight shadow */
    backdrop-filter: blur(10px); /* Glass effect */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .certification-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Slightly darker shadow */
  }
  
  .certification-card h4 {
    font-size: 1.3rem;
    margin-bottom: 5px;
    color: #fff; /* White text */
  }
  
  .certification-card p {
    font-size: 1rem;
    color: #ccc; /* Softer white */
  }
  
  /* Mobile Responsiveness */
  @media screen and (max-width: 768px) {
    .skills-certifications-section {
      padding: 8% 5%;
    }
  
    .intro-text h2 {
      font-size: 2rem;
    }
  
    .intro-text p {
      font-size: 1rem;
    }
  
    .skills-container h3, .certifications-container h3 {
      font-size: 1.8rem;
    }
  
    .skills-container li {
      font-size: 1rem;
    }
  
    .certification-card h4 {
      font-size: 1.2rem;
    }
  
    .certification-card p {
      font-size: 0.9rem;
    }
  }
  
  /* Animation */
  .skills-container li, .certification-card {
    animation: fadeInUp 0.8s ease-in-out;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  