/* Contact Section */
.contact-section {
    background-color: #000; /* Black background */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    color: #fff;
    overflow-x: hidden; /* Prevent horizontal overflow */
  }
  
  /* Container for the form and branding */
  .contact-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1500px;
    box-sizing: border-box;
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
    flex-direction: row; /* Keep it horizontal by default */
  }
  
  /* Branding Section */
  .branding-container {
    width: 40%;
    color: #fff;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 30px; /* Space between branding and form on mobile */
  }
  
  /* Joy's Music Title */
  .branding-container h3 {
    font-family: 'Playfair Display', serif; /* Use Playfair Display font */
    font-size: 2rem;
    margin-bottom: 10px;
    color: #fff;
  }
  
  /* Branding Description */
  .branding-container p {
    font-family: 'Roboto', sans-serif; /* Use Roboto for description */
    font-size: 1rem;
    color: #ddd;
  }
  
/* Music Icons */
.music-icons {
    display: flex;
    justify-content: center;
    gap: 15px; /* Space between icons */
    margin-top: 20px;
  }
  
  .music-icons a {
    color: #fff;
    text-decoration: none;
    font-size: 1.5rem; /* Reduced size of icons */
    display: inline-block;
    transition: all 0.3s ease; /* Smooth transition for all properties */
  }
  
  .music-icons a:hover {
    color: #fff; /* Keep text color white on hover */
    transform: scale(1.1); /* Slightly enlarge icon on hover */
  }
  
  /* Specific hover colors for each platform */
  .music-icons a:hover:nth-child(1) { /* Amazon Music */
    color: #00A8E8; /* Official Amazon Music color */
  }
  
  .music-icons a:hover:nth-child(2) { /* Spotify */
    color: #1DB954; /* Official Spotify color */
  }
  
  .music-icons a:hover:nth-child(3) { /* Apple Music - Gradient */
    color: #7089a5;
  }
  
  .music-icons a:hover:nth-child(4) { /* SoundCloud */
    color: #FF5500; /* Official SoundCloud color */
  }
  
  .music-icons a:hover:nth-child(5) { /* YouTube Music */
    color: #FF0000; /* Official YouTube Music color */
  }
  
  /* Focus effect */
  .music-icons a:focus {
    outline: none; /* Remove default focus outline */
  }
  
  /* For responsiveness */
  @media (max-width: 768px) {
    .music-icons a {
      font-size: 1.2rem; /* Reduce icon size on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .music-icons a {
      font-size: 1rem; /* Further reduce icon size on very small screens */
    }
  }
  
  
  
  /* Form Container */
  .form-container {
    background: rgba(255, 255, 255, 0.1); /* Transparent background */
    backdrop-filter: blur(15px); /* Glassmorphism effect */
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    width: 50%; /* Make it half width on large screens */
    color: #fff;
    box-sizing: border-box; /* Ensure padding doesn't exceed box */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  /* Form Inputs */
  .contact-form input,
  .contact-form textarea {
    width: 100%; /* Force width to 100% */
    padding: 12px;
    margin: 10px 0;
    border: none;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.2); /* Transparent background */
    color: #fff;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    box-sizing: border-box; /* Ensure padding does not cause overflow */
  }
  
  /* Fixed Message Box */
  .contact-form textarea {
    height: 150px; /* Fixed height for message box */
    resize: none; /* Disable resizing */
  }
  
  /* Submit Button */
  .contact-form button {
    width: 100%; /* Ensure button is full width */
    padding: 12px;
    border: none;
    border-radius: 8px;
    background-color: #fff;
    color: #000;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-form button:hover {
    background-color: #ddd;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .contact-container {
      flex-direction: column-reverse; /* Reversed order to position the branding at the top */
      align-items: flex-start;
    }
  
    .form-container {
      width: 100%;
      margin-bottom: 30px;
    }
  
    .branding-container {
      width: 100%;
      text-align: left;
    }
  
    .form-container h2 {
      font-size: 1.5rem;
    }
  
    .form-container p {
      font-size: 0.9rem;
    }
  
    .contact-form input,
    .contact-form textarea {
      font-size: 0.9rem;
      width: 100%; /* Set to 100% for smaller screens */
    }
  
    /* Adjust message box on mobile */
    .contact-form textarea {
      height: 120px; /* Reduce height on smaller screens */
    }
  }
  
  @media screen and (max-width: 480px) {
    .contact-container {
      padding: 24px; /* Reduced padding for mobile screens */
    }
  
    .form-container h2 {
      font-size: 1.2rem;
    }
  
    .form-container p {
      font-size: 0.8rem;
    }
  
    .contact-form input,
    .contact-form textarea {
      font-size: 0.8rem;
      width: 100%; /* Ensure full width for small screens */
    }
  
    .contact-form textarea {
      height: 100px; /* Further reduce height of the message box */
    }
  }
  