/* Gallery Section */
.gallery-section {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    color: white;
    position: relative;
    height: 100vh; /* Full-screen height */
    box-sizing: border-box;
    overflow: hidden;
    padding: 5% 8%;
  }
  
  /* Content Layout: Text on the left, Image on the right */
  .gallery-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }
  
  /* Description Section */
  .gallery-description {
    flex-basis: 40%;
    margin-right: 3%;
    text-align: left;
    margin-left: -3%;
  }
  
  .gallery-title {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #1db954;
  }
  
  .gallery-subtitle {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .gallery-story {
    font-size: 1rem;
    line-height: 1.5;
  }
  
  /* Image Container */
  .gallery-image-container {
    flex-basis: 55%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
    margin-left: 5%;
    position: relative;
  }
  
  .image-slider {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease;
  }
  
  .gallery-image {
    flex: 0 0 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Slide Animation */
  .image-slider.slide-next {
    transform: translateX(-100%);
  }
  
  .image-slider.slide-prev {
    transform: translateX(100%);
  }
  
  /* Navigation Controls */
  .navigation-controls {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    width: 90%;
    z-index: 10;
  }
  
  .prev, .next {
    background-color: #1db954;
    color: white;
    border: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .prev:hover, .next:hover {
    background-color: #1ed760;
    transform: scale(1.1);
  }
  
  /* Mobile Responsiveness */
  @media screen and (max-width: 768px) {
    .gallery-section {
      padding: 2% 5%; /* Reduce padding for smaller screens */
    }
  
    .gallery-content {
      flex-direction: column;
      align-items: center;
      height: auto; /* Allow content to take up necessary height */
    }
  
    .gallery-description {
      flex-basis: 100%;
      margin-right: 0;
      text-align: center;
      margin-left: 0;
      margin-bottom: 20px; /* Add spacing below text */
    }
  
    .gallery-image-container {
      flex-basis: 100%;
      height: auto; /* Allow the container to adjust height dynamically */
      margin-left: 0;
    }
  
    .gallery-title {
      font-size: 1.8rem;
    }
  
    .gallery-subtitle {
      font-size: 1rem;
    }
  
    .gallery-story {
      font-size: 0.9rem;
    }
  
    .prev, .next {
      width: 40px;
      height: 40px;
      font-size: 1.2rem;
    }
  }
  