


/* Footer Section */

.footer-section {
    background-color: #0a0a0a; /* Black background */
    color: #fff; /* White text */
    padding: 15px 8%; /* Reduced padding for shorter height */
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto', sans-serif;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 1200px;
  }
  
  /* Branding and Tagline */
  .footer-branding h2 {
    font-family: 'Playfair Display', serif;
    font-size: 1.8rem;
    color: #1db954;
    margin-bottom: 10px;
  }
  
  .footer-branding p {
    font-size: 1rem;
    line-height: 1.5;
    color: #ddd;
    margin: 5px 0;
  }
  
  /* Quick Links */
  .footer-links h3,
  .footer-contact h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #1db954;
  }
  
  .footer-links ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-links li {
    margin-bottom: 8px;
  }
  
  .footer-links a {
    color: #ddd;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-links a:hover {
    color: #1db954;
  }
  
  /* Contact Info */
  .footer-contact p {
    font-size: 0.9rem;
    line-height: 1.5;
  }
  
  .footer-contact a {
    color: #ddd;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-contact a:hover {
    color: #1db954;
  }
  
  /* Social Icons */
  .social-icons {
    display: flex;
    gap: 15px;
    margin-top: 10px;
  }
  
  .social-icons a {
    font-size: 1.5rem;
    color: #ddd;
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
  .social-icons a:hover {
    transform: scale(1.2);
  }
  
  .social-icons a:nth-child(1):hover { color: #1877f2; }
  .social-icons a:nth-child(2):hover { color: #e4405f; }
  .social-icons a:nth-child(3):hover { color: #ff0000; }
  .social-icons a:nth-child(4):hover { color: #1db954; }
  
  /* Footer Bottom */
  .footer-bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 10px;
    text-align: center;
    width: 100%;
    font-size: 0.8rem;
    color: #ddd;
  }
  
  /* Footer Link Styling */
  .footer-link {
    color: #1db954;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-link:hover {
    color: #fff;
  }
  
  /* Scroll to Top Button with Glassmorphism */
.scroll-to-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    background: rgba(255, 255, 255, 0.1); /* Semi-transparent white */
    backdrop-filter: blur(10px); /* Creates the frosted glass effect */
    border: none;
    border-radius: 50%;
    padding: 15px;
    font-size: 1.5rem;
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Shadow to make it pop */
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Button hover effect */
  .scroll-to-top:hover {
    background: rgba(255, 255, 255, 0.3); /* Lighter frosted glass on hover */
    transform: scale(1.1);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3); /* More prominent shadow on hover */
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .scroll-to-top {
      bottom: 20px;
      right: 20px;
    }
  }
  
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
    }
  
    .footer-links,
    .footer-contact {
      margin-left: 0;
      margin-top: 20px;
    }
  
    .footer-branding {
      margin-bottom: 20px;
    }
  
    .social-icons a {
      font-size: 1.3rem;
      margin-left: 3px;
    }
  
    .social-icons {
      font-size: 1.3rem;
      margin-left: -55px;
    }
  
    .scroll-to-top {
      bottom: 20px; /* Adjust the button position on mobile */
      right: 20px;
    }
  }
  